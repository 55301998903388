// src/context/AppContext.js
import React, { createContext, useState } from 'react';

export const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [roleOption, setRoleOption] = useState("1");
    const [appointmentDate, setAppointmentDate] = useState("11/15/2024 00:00");
    const [phone, setPhone] = useState("923494471667");
    const [name, setName] = useState("Hello");
    const [payStatus, setPayStatus] = useState("0");
    const [paymentEnabled, setPaymentEnabled] = useState(1);

    return (
        <AppContext.Provider
            value={{
                roleOption,
                setRoleOption,
                appointmentDate,
                setAppointmentDate,
                phone,
                setPhone,
                name,
                setName,
                payStatus,
                setPayStatus,
                paymentEnabled,
                setPaymentEnabled,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
