import { Container, styled } from "@mui/material";

//Style Main container
export const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "100vw",
  minHeight: "calc(100vh - 50px)",
  // overflow: "hidden",
  backgroundImage: `url(background.jpg)`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));
