import axios from "axios";
import { styled } from "@mui/system";
import React, { useContext, useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StyledContainer } from "../../helper/utils";
import { Box, Button, CssBaseline, Typography } from "@mui/material";
import { AppContext } from "../../context/app-context";

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%", // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const UserPage = () => {
  const { t } = useTranslation();
  const [ph, setPh] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);  // New flag to track form submission
  const navigate = useNavigate();
  const {
    phone,
    payStatus,
    paymentEnabled,
    roleOption,
    setPhone,
    setName,
    setRoleOption,
    setPayStatus,
    setAppointmentDate,
    setPaymentEnabled,
  } = useContext(AppContext);

  // Effect to handle navigation after the form is submitted
  useEffect(() => {
    if (isFormSubmitted) {
      // Make sure form has been submitted before checking conditions
      if (payStatus === "1") {
        navigate("/success");
      } else if (payStatus === "0" && roleOption === "2") {
        navigate("/pay_stripe");
      } else if (payStatus === "0" && roleOption === "1") {
        navigate("/pay_stripe");
      } else if (payStatus === "0" && roleOption === "2") {
        navigate("/check_game");
      } else if (payStatus === "0" && roleOption === "1") {
        navigate("/check_game");
      } else {
        navigate("/oops");
      }
    }
  }, [isFormSubmitted, payStatus, roleOption, navigate]);  // Add isFormSubmitted to dependency

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = { phone: ph };
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/signin_phone`, { formData });

      // Store data in local storage
      localStorage.setItem("authUser", "true");
      localStorage.setItem("phone", response.data.data.phone);
      localStorage.setItem("role_option", response.data.data.role_option);
      localStorage.setItem("appointmentDate", response.data.data.appointmentdate);
      localStorage.setItem("name", response.data.data.firstname);
      localStorage.setItem("payStatus", response.data.data.paystatus);
      localStorage.setItem("paymentEnabled", response.data.data.payment_enabled.toString());

      // Update state values from response data
      setPhone(response.data.data.phone);
      setRoleOption(response.data.data.role_option);
      setAppointmentDate(response.data.data.appointmentdate);
      setName(response.data.data.firstname);
      setPayStatus(response.data.data.paystatus);
      setPaymentEnabled(response.data.data.payment_enabled);

      // Mark form as submitted
      setIsFormSubmitted(true); // Flag that the form has been successfully submitted

    } catch (err) {
      // Handle error and navigate to 'oops' page
      navigate("/oops");
    }
  };

  return (
    <StyledContainer component="main" maxWidth="disable">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "rgba(255, 251, 240, 0.95)", // Semi-transparent background
          margin: "10px 0",
          padding: 4,
          width: { sm: 400, xs: 1 },
          borderRadius: 1,
          ".form-control": {
            width: "100%",
          },
          ".country-list .search-box": {
            height: 30,
          },
        }}
      >
        <Typography component="h1" variant="h5">
          {t("welcome")}
        </Typography>
        <StyledForm onSubmit={handleSubmit}>
          <PhoneInput
            id="phone"
            value={ph}
            onChange={(ph) => setPh(ph)}
            copyNumbersOnly
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
              height: "50px",
            }}
            preferredCountries={["fr", "us", "gb"]}
            enableSearch={true}
            containerStyle={{ height: "50px", background: "none" }}
            inputStyle={{ height: "50px", background: "none" }}
          />
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            {t("submit")}
          </StyledButton>
        </StyledForm>
      </Box>
    </StyledContainer>
  );
};

export default UserPage;
